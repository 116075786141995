@if (showError) {
  <div class="alert alert-danger">
    <div class="error-title">{{ errorTitle | translate }}</div>
    <div>{{ errorMessage | translate }}</div>
  </div>
}
<div class="cbsw-booking-questions-step">
  <div [@fadeInFadeOut]="counter">
    @for (widgetQuestion of widgetQuestions; track widgetQuestion; let index = $index; let isFirst = $first; let isLast = $last) {
      @if (activeQuestion === index) {
        <app-booking-question-view
          [widgetQuestion]="widgetQuestion"
          [isFirst]="isFirst"
          [isLast]="isLast"
          (previousQuestionEvent)="decrement(); previousQuestion(isFirst, widgetQuestion)"
          (nextQuestionEvent)="increment(); nextQuestion(widgetQuestion)"
          (finishLastBookingQuestionEvent)="increment(); finishLastBookingQuestion(widgetQuestion)"
        />
      }
    }
  </div>
</div>
