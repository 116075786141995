@if (!noAppointments;) {
  @if (hasGroups) {
    <mat-tab-group
      [selectedIndex]="0"
      [dynamicHeight]="true"
      [disableRipple]="true"
    >
      @for (appointmentsGroup of freeAppointments; track appointmentsGroup; let tabIndex = $index) {
        @if (appointmentsGroup.widgetGroup) {
          <mat-tab>
            <ng-template mat-tab-label>
              {{ appointmentsGroup.widgetGroup | translation: 'name' : lang }}
              @if (appointmentsGroup.selectedSlots > 0) {
                <span
                  class="badge tabs-slot-counter ms-2"
                  [style.background-color]="(widgetService?.widgetConf?.header_background_color || undefined)"
                >
                  {{ appointmentsGroup.selectedSlots }}
                </span>
              }
            </ng-template>
            <div class="row">
              <ng-container *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, tabIndex: tabIndex }" />
            </div>
          </mat-tab>
        }
      }
    </mat-tab-group>
  }
  @if (!hasGroups) {
    @for (appointmentsGroup of freeAppointments; track appointmentsGroup; let tabIndex = $index) {
      <div class="row mt--3">
        <ng-container *ngTemplateOutlet="slotsBlock; context: { $implicit: appointmentsGroup, tabIndex: tabIndex }" />
      </div>
    }
  }
  <ng-template #slotsBlock let-appointmentsGroup let-tabIndex="tabIndex">
    @for (appointment of appointmentsGroup.appointments; track appointment; let appointmentindex = $index) {
      @if (appointmentsGroup?.loadMore === true || appointmentindex < 10) {
        <div class="mt-3 col-xs-12 col-sm-6">
          <app-info-card
            [showBody]="true"
            [showShadow]="false"
            [isSelected]="appointment.selected"
            [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
            [isDisabled]="appointment.disabled"
            [showCardBorder]="true"
            [customCardClass]="appointment.selected ? 'cbsw-next-icon' : ''"
            [customCardStylesConfig]="{
              borderWidth: '1px',
              borderColor: '#d6d6d6',
              borderStyle: 'soDlid',
              borderRadius: '8px'
            }"
          >
            <div cardBody>
              <div class="d-flex align-items-center">
                <mat-checkbox
                  [disableRipple]="true"
                  (change)="onSelectAppointment($event, appointment)"
                  [checked]="appointment.selected"
                  [disabled]="appointment.disabled"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-column align-items-center justify-content-center w-100">
                      @if (selectedWorkerId > 0 || bookingService?.partnerData?.anyone_feature_hide_ressource === 0) {
                        <div class="resource-name">
                          @if (appointment?.worker?.is_resource === 1) {
                            @if (appointment?.worker?.is_multi_language === 1) {
                              @if ((appointment?.worker | translation: 'booking_label': lang)) {
                                {{ appointment?.worker | translation: 'booking_label': lang }}
                              }
                              @if (!(appointment?.worker | translation: 'booking_label': lang)) {
                                {{ appointment?.worker | translation: 'resource_name': lang }}
                              }
                            }
                            @if (appointment?.worker?.is_multi_language === 0) {
                              @if (appointment?.worker?.booking_label) {
                                {{ appointment?.worker?.booking_label }}
                              }
                              @if (!appointment?.worker?.booking_label) {
                                {{ appointment?.worker?.resource_name }}
                              }
                            }
                          }
                          @if (appointment?.worker?.is_resource === 0) {
                            {{ appointment?.worker?.resource_name }}
                          }
                        </div>
                      }
                      <div
                        [ngClass]="{
                          'hour-slot': true,
                          'font-weight-bold': (selectedWorkerId === 0)
                        }"  
                      >
                        {{ appointment.start | dateutc:timeFormat }} - {{ appointment.end | dateutc:timeFormat }}
                      </div>
                    </div>
                  </div>
                </mat-checkbox>
                @if (appointment.selected) {
                  <div class="next-arrow-container">
                    <span (click)="gotoNextPage()">
                      <div class="next-button-wrapper">
                        <img src="https://my.calenso.com/images/next_icon_widget.svg" width="26px">
                      </div>
                    </span>
                  </div>
                }
              </div>
            </div>
          </app-info-card>
        </div>
      }
    }
    @if (
      (appointmentsGroup?.loadMore === false || appointmentsGroup?.loadMore === undefined) &&
      appointmentsGroup.appointments &&
      appointmentsGroup.appointments.length >= 10
    ) {
      <div class="col-md-12 mt-4 text-center load-more-link">
        <app-csw-link
          [styleConfig]="{
            'color': widgetService?.widgetConf?.header_background_color,
            'textDecorationColor': widgetService?.widgetConf?.header_background_color
          }"
          [isLink]="false"
          [text]="'booking_components.load_more' | translate"
          (clickEvent)="loadMore(tabIndex)"
        />
      </div>
    }
  </ng-template>
}
