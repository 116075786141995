<div class="mt-3 cbsw-services-selection-step">
  @if (showErrorMessage) {
    <div>
      <app-alert
        [isAlertMessageTranslated]="true"
        [type]="'danger'"
        [message]="'error_messages.no_services_found'"
      />
    </div>
  }
  @if (showDependenciesGhostElement) {
    <div class="skeleton-box-card">
      <div>
        @for (dependenciesGhostElement of dependenciesGhostElements; track
        dependenciesGhostElement) {
        <div class="row mt-4">
          <div class="col-12 mb-3">
            <div class="heading-element"></div>
          </div>
          @for (dependenciesGhostElement of dependenciesGhostElements; track dependenciesGhostElement) {
          <div class="col-6 mb-4">
            <div class="d-flex stats-card shadow">
              <div class="skeleton-animation w-100 d-flex flex-column">
                <div class="body-container">
                  <div class="stat-box-1"></div>
                  <div class="stat-box mt-2"></div>
                </div>
                <div class="border-top"></div>
              </div>
            </div>
          </div>
          }
        </div>
        }
      </div>
    </div>
  }
  @if (servicesGroupedData?.length > 0) {
    <div class="service-title">
      <app-csw-text
        [customClass]="'step-element-title'"
        [text]="serviceLabelTemplate | translation : 'template_content' : lang"
        [styleConfig]="{ fontWeight: 600 }"
      />
    </div>
    @for (serviceGroupedData of servicesGroupedData; track serviceGroupedData) {
    <div class="category-title">
      <app-csw-text
        [text]="serviceGroupedData?.temp?.appointment_service_category | translation : 'name' : lang | calioSafe : 'html'"
        [styleConfig]="{ fontWeight: 600, fontSize: '13px' }"
      />
    </div>
    @if (!widgetService?.widgetConf?.context?.show_appointment_services_list) {
    <swiper [slidesPerView]="2" [spaceBetween]="20" [freeMode]="true">
      @for (serviceData of serviceGroupedData.value; track serviceData) {
      <ng-template swiperSlide>
        <div class="mt-3">
          <ng-container *ngTemplateOutlet="serviceDataTemplate; context: { serviceData: serviceData }" />
        </div>
      </ng-template>
      }
    </swiper>
    }
    @if (widgetService?.widgetConf?.context?.show_appointment_services_list) {
      <div class="row service-list-container">
        @for (serviceData of serviceGroupedData.value; track serviceData) {
          <div class="col-md-6 mt-3">
            <div>
              <ng-container *ngTemplateOutlet="serviceDataTemplate; context: { serviceData: serviceData }" />
            </div>
          </div>
        }
      </div>
      }
    }
    <div class="d-flex justify-content-end mt-4 mb-4 pe-3 cbw-custom-navigation">
      <div class="me-2">
        <app-button
          id="{{ 'services-selection-previous-btn' }}"
          (clickEvent)="previous()"
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          <span class="custom-button-icon back"></span>
          {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 129, type: "template" } | translation : "template_content" : lang }}
        </app-button>
      </div>
      <div>
        <app-button
          id="{{ 'services-selection-next-btn' }}"
          (clickEvent)="next()"
          [isDisabled]="
            bookingService.selectedBookingData.currentSelectedAppointmentState
              .selectedServiceIds.length === 0
          "
          [fontColor]="widgetService?.widgetConf?.button_font_color"
          [backgroundColor]="widgetService?.widgetConf?.button_background_color"
        >
          {{ bookingService.widgetTemplates | calioMeetingTemplate : { id: 128, type: "template" } | translation : "template_content" : lang }}
          <span class="custom-button-icon next"></span>
        </app-button>
      </div>
    </div>
  }
</div>
<ng-template #serviceDataTemplate let-serviceData="serviceData">
  <app-info-card
    [showCursorPointer]="true"
    [showBody]="true"
    [showFooter]="true"
    [selectedCardColor]="widgetService?.widgetConf?.header_background_color"
    [isSelected]="bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1"
    (cardClickEvent)="toggleServiceSelection(serviceData)"
  >
    <div cardBody>
      <div>
        <app-csw-text
          [text]="serviceData | translation : 'name' : lang | calioSafe : 'html'"
          [styleConfig]="{ fontWeight: 600, fontSize: '13px' }"
        />
      </div>
        @if (serviceData.description && bookingService.partnerData.show_inline_description === 1) {
          <div>
            <app-csw-text
              [text]="serviceData | translation : 'description' : lang"
              [styleConfig]="{ fontSize: '13px' }"
            />
          </div>
        }
      <div>
        @if ((serviceData.duration | replaceComma | toHour) > 0) {
          <app-csw-text
            [text]="(serviceData.duration | replaceComma | toHour) + ('booking_components.durationHour' | translate)"
            [styleConfig]="{ fontSize: '13px' }"
          />
        }
        @if ((serviceData.duration | replaceComma | toMinute) > 0) {
          <app-csw-text
            [text]="(serviceData.duration | replaceComma | toMinute) + ('booking_components.durationMinute' | translate)"
            [styleConfig]="{ fontSize: '13px' }"
          />
        }
        @if (serviceData.price > 0) {
          <app-csw-text
            [text]="'/ ' + (serviceData.price | calioCurrency : bookingService.partnerData['currency'])"
            [styleConfig]="{ fontSize: '13px' }"
          />
          @if (serviceData?.tax?.rate > 0) {
            <small class="taxes text-muted">
              {{ "booking_components.taxes" | translate : { percentage: serviceData?.tax?.rate } }}
            </small>
          }
        }
        @if (serviceData.price === 0) {
          {{ "booking_components.free" | translate }}
        }
      </div>
    </div>
    <div cardFooter>
      <div
        class="d-flex align-items-center"
        [ngClass]="{
          'justify-content-between': bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1,
          'justify-content-center': bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) === -1
        }"
      >
        <div class="d-flex align-items-center">
          @if (bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) === -1) {
            <app-csw-link
              [isLink]="false"
              [styleConfig]="{
                color: widgetService?.widgetConf?.header_background_color,
                textDecorationColor: widgetService?.widgetConf?.header_background_color
              }"
              text="{{ 'booking_components.select' | translate }}"
            />
          }
          @if (bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1) {
            <app-csw-link
              [isLink]="false"
              [styleConfig]="{
                color: widgetService?.widgetConf?.header_background_color,
                textDecorationColor: widgetService?.widgetConf?.header_background_color
              }"
              text="{{ 'booking_components.deselect' | translate }}"
            />
          }
        </div>
        @if (bookingService.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds.indexOf(serviceData.id) > -1) {
          <div class="next-arrow-container">
            <span (click)="next()">
              <img
                src="https://my.calenso.com/images/next_icon_widget.svg"
                width="26px"
                alt="next_icon_widget"
              />
            </span>
          </div>
        }
      </div>
    </div>
  </app-info-card>
</ng-template>
