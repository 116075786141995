import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { fadeInFadeOut } from '@animations/fade';
import { BookingFailedViewComponent } from '@components/booking-failed-view/booking-failed-view.component';
import { BookingQuestionsViewComponent } from '@components/booking-questions-view/booking-questions-view.component';
import { BookingSuccessViewComponent } from '@components/booking-success-view/booking-success-view.component';
import { BookingSummaryViewComponent } from '@components/booking-summary-view/booking-summary-view.component';
import { MeetingTypeSelectionComponent } from '@components/meeting-type-selection/meeting-type-selection.component';
import { PersonalFormViewComponent } from '@components/personal-form-view/personal-form-view.component';
import { ServicesSelectionComponent } from '@components/services-selection/services-selection.component';
import { SlotsSelectionComponent } from '@components/slots-selection/slots-selection.component';
import { StoreSelectionComponent } from '@components/store-selection/store-selection.component';
import { WorkersSelectionComponent } from '@components/workers-selection/workers-selection.component';
import { BOOKING_PAGE_VIEWS } from '@constants/app.constants';
import { WINDOW_LISTENER_CONSTANTS } from '@constants/window-listener.constants';
import { PartnerDbModel, TokenType } from '@models/partner-db.model';
import { WidgetTemplateModel } from '@models/widget-template.model';
import { CalioSafePipe } from '@pipes/calio-safe.pipe';
import { BookingService } from '@services/feature/booking.service';
import { WidgetService } from '@services/feature/widget.service';
import { LoggerService } from '@services/utils/logger.service';
import { GtmOperationComponent } from '@ui-lib/gtm-operation/gtm-operation.component';


@Component({
    selector: 'app-booking',
    templateUrl: './booking.component.html',
    styleUrls: ['./booking.component.scss'],
    animations: [fadeInFadeOut],
    standalone: true,
    imports: [
    BookingQuestionsViewComponent,
    MeetingTypeSelectionComponent,
    StoreSelectionComponent,
    ServicesSelectionComponent,
    WorkersSelectionComponent,
    SlotsSelectionComponent,
    PersonalFormViewComponent,
    BookingSummaryViewComponent,
    BookingSuccessViewComponent,
    BookingFailedViewComponent,
    GtmOperationComponent,
    CalioSafePipe
],
})
export class BookingComponent implements OnInit {

  readonly bookingPageViews = BOOKING_PAGE_VIEWS;

  counter = 0;
  partnerData: PartnerDbModel;
  selectedView = BOOKING_PAGE_VIEWS.STORE_VIEW;
  widgetTemplates: WidgetTemplateModel[] = [];
  isFirstBookingQuestionActive = true;
  isLastBookingQuestionActive = false;

  constructor(
    public widgetService: WidgetService,
    public bookingService: BookingService
  ) {
    if (this.widgetService.widgetConf?.context?.show_questions_before_booking_process) {
      this.selectedView = BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW;
    } else if (this.widgetService.widgetConf?.context?.show_meeting_type_picker) {
      this.selectedView = BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW;
    }

    this.bookingService.initialSmartWidgetView = this.selectedView;
  }

  ngOnInit(): void {
    this.initializeBookingWidget(this.widgetService.partnerUUID);
  }

  initializeBookingWidget(partnerUUID: string): void {
    this.bookingService.initializeBookingWidget(partnerUUID, TokenType.publicSmartwidget).subscribe({
      next: partner => {
        if (partner) {
          this.partnerData = partner;

          if (this.partnerData?.adobe_launch_tracking_url && this.partnerData?.adobe_launch_tracking_url?.trim() !== '') {
            this.loadAdobeAnalytics(this.partnerData.adobe_launch_tracking_url);
          }

          this.bookingService.partnerData = this.partnerData;
          this.preinitializeWidgetContextValues();
          this.getWidgetTemplates();
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  loadAdobeAnalytics(adobe_launch_tracking_url: string): void {
    try {
      const script = document.createElement('script');
      script.src = adobe_launch_tracking_url;
      script.async = true;
      document.head.appendChild(script);
      LoggerService.log('Adobe Launch tracking script added.');
    } catch (error) {
      LoggerService.warn('Error appending dobe Launch tracking script');
      LoggerService.error(error);
    }
  }

  preinitializeWidgetContextValues(): void {
    this.bookingService.loadAppointmentStateFromLocalStorage();
    this.bookingService.preInitializeWidgetContextValues(
      this.widgetService.widgetConf?.context,
      this.widgetService.customerConfData,
    );
    this.bookingService.loadAppointmentSelectedUserFromLocalStorage();
    this.bookingService.preInitializeWidgetUserData(this.widgetService.customerConfData);
  }

  getWidgetTemplates(): void {
    this.bookingService.getWidgetTemplates().subscribe({
      next: templates => {
        this.widgetTemplates = templates;
        this.bookingService.widgetTemplates = this.widgetTemplates;
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  gotoView(view: string): void {
    this.selectedView = view;
    if (this.widgetService?.parentToChildOriginEvent) {
      this.widgetService.sendMessageToMainJs(this.widgetService.parentToChildOriginEvent, {
        eventName: WINDOW_LISTENER_CONSTANTS.PARENT_BOOKING_WEBSITE_EVENT,
        name: 'Child To Parent Communication Done',
        view
      });
    }
  }

  increment(): void {
    this.counter++;
  }

  decrement(): void {
    this.counter--;
  }
}
