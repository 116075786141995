import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {LoggerService} from './logger.service';
import {WidgetService} from '../feature/widget.service';
import {CustomEventService} from '../feature/custom-event.service';
import {WidgetConfModel, WidgetContext} from '../../models/widget-conf.model';
import {CustomerDbModel} from '../../models/customer.model';
import {HelperService} from './helper.service';
import {LocalstorageService} from './localstorage.service';
import {AppointmentServiceModel} from 'src/app/models/appointment-service.model';
import {DomManipulationService} from './dom-manipulation.service';

@Injectable({
  providedIn: 'root'
})
export class SwRouteService {

  private queryParamsData: {
    widgetContext: WidgetContext,
    customerConfData: CustomerDbModel,
    customFieldValues: { id?: number, value?: any, ignore?: boolean }[],
    referenceAppointment?: {
      uuid: string,
      appointment: AppointmentServiceModel
    }
  } = {
    widgetContext: new WidgetContext(),
    customerConfData: new CustomerDbModel(),
    customFieldValues: [],
    referenceAppointment: {
      uuid: null,
      appointment: null
    }
  };

  set setReferenceAppointment(appointment: AppointmentServiceModel) {
    this.queryParamsData.referenceAppointment.appointment = appointment;
  }
  get getReferenceAppointment(): AppointmentServiceModel {
    return this.queryParamsData.referenceAppointment.appointment;
  }

  set setReferenceAppointmentUuid(uuid: string) {
    this.queryParamsData.referenceAppointment.uuid = uuid;
  }
  get getReferenceAppointmentUuid(): string {
    return this.queryParamsData.referenceAppointment.uuid;
  }

  get getReferenceAppointmentId(): number {
    const appointment = this.getReferenceAppointment;
    return appointment?.id ? appointment.id : undefined;
  }

  constructor(
    private route: ActivatedRoute,
    public widgetService: WidgetService,
    private customEventService: CustomEventService,
    private helperService: HelperService,
    private localStorageService: LocalstorageService,
    private domManuplationService: DomManipulationService
  ) {

    this.route.queryParams.subscribe(
      (result: Params) => {
        LoggerService.log('Params is ', result);
        LoggerService.log('this.route ', this.route);
        if (result.partner_uuid && result.widget_uuid) {
          this.customEventService.setWidgetConfigurationFromQueryParamsEvent.emit({
            partner_uuid: result.partner_uuid,
            widget_uuid: result.widget_uuid,
          });
        }

        if (Number(result.refresh) === 1) {
          this.localStorageService.clear();
        }

        if (result?.appointment_uuid) {
          this.validateReferenceAppointmentAndSetInContext(result.appointment_uuid);
        }

        this.setCustomerConfData(result);
        this.setWidgetContextData(result);
        this.setCustomFieldValues(result);
      }
    );
  }

  changeCustomFieldStateToIgnore(id: number): void {
    if (this.queryParamsData.customFieldValues?.length > 0) {
      LoggerService.log('this.queryParamsData.customFieldValues ', this.queryParamsData.customFieldValues);
      for (const cf of this.queryParamsData.customFieldValues) {
        if (cf.id === id) {
          cf.ignore = true;
        }
      }
    }
  }

  getCustomFieldValues(): { id?: number, value?: any, ignore?: boolean }[] {
    return this.queryParamsData.customFieldValues;
  }

  overrideWidgetConfigDataFromQueryParamData(widgetConf: WidgetConfModel): WidgetConfModel {
    if (!widgetConf?.context) {
      widgetConf.context = new WidgetContext();
    }
    if (this.queryParamsData.widgetContext.worker_email) {
      LoggerService.warn('Overriding worker_email');
      widgetConf.context.worker_email = this.queryParamsData.widgetContext.worker_email;
    }
    if (this.queryParamsData.widgetContext.store_id) {
      LoggerService.warn('Overriding store');
      widgetConf.context.store_id = this.queryParamsData.widgetContext.store_id;
    }
    if (this.queryParamsData.widgetContext?.appointment_service_ids?.length > 0) {
      LoggerService.warn('Overriding appointment services');
      widgetConf.context.appointment_service_ids = this.queryParamsData.widgetContext.appointment_service_ids;
    }
    if (this.queryParamsData.widgetContext.worker_id) {
      LoggerService.warn('Overriding worker Id');
      widgetConf.context.worker_id = this.queryParamsData.widgetContext.worker_id;
    }
    if (this.queryParamsData.widgetContext?.worker_ids?.length > 0) {
      LoggerService.warn('Overriding worker Ids');
      widgetConf.context.worker_ids = this.queryParamsData.widgetContext.worker_ids;
    }
    if (this.queryParamsData.widgetContext.worker_uuid) {
      LoggerService.warn('Overriding worker uuid');
      widgetConf.context.worker_uuid = this.queryParamsData.widgetContext.worker_uuid;
    }
    if (this.queryParamsData.widgetContext.store_id) {
      LoggerService.warn('Overriding store id');
      widgetConf.context.store_id = this.queryParamsData.widgetContext.store_id;
    }
    if (this.queryParamsData.widgetContext.locale) {
      LoggerService.warn('Overriding locale');
      widgetConf.context.locale = this.queryParamsData.widgetContext.locale;
    }
    if (this.queryParamsData.widgetContext.date) {
      LoggerService.warn('Overriding date');
      widgetConf.context.date = this.queryParamsData.widgetContext.date;
    }
    if (this.queryParamsData.widgetContext.is_internal) {
      LoggerService.warn('Overriding is_internal');
      widgetConf.context.is_internal = this.queryParamsData.widgetContext.is_internal;
    }
    if (this.queryParamsData.widgetContext.booking_questions?.length > 0) {
      LoggerService.warn('Overriding booking questions');
      widgetConf.context.booking_questions = this.queryParamsData.widgetContext.booking_questions;
    }
    if (this.queryParamsData.widgetContext.meeting_providers?.length > 0) {
      LoggerService.warn('Overriding meeting providers');
      widgetConf.context.meeting_providers = this.queryParamsData.widgetContext.meeting_providers;
    }
    if (this.queryParamsData.widgetContext.customer?.length > 0) {
      LoggerService.warn('Overriding Customer');
      widgetConf.context.customer = this.queryParamsData.widgetContext.customer;
    }
    if (this.queryParamsData.widgetContext.customer_required?.length > 0) {
      LoggerService.warn('Overriding Customer Required Fields');
      widgetConf.context.customer_required = this.queryParamsData.widgetContext.customer_required;
    }
    /*if (this.queryParamsData.widgetContext.customer_internal?.length > 0) {
      LoggerService.warn('Overriding Customer Internal Fields');
      widgetConf.context.customer_internal = this.queryParamsData.widgetContext.customer_internal;
    }*/
    /*if (this.queryParamsData.widgetContext.customer_readonly?.length > 0) {
      LoggerService.warn('Overriding Customer Readonly Fields');
      widgetConf.context.customer_readonly = this.queryParamsData.widgetContext.customer_readonly;
    }*/
    if (this.queryParamsData.widgetContext.customer_type_id) {
      LoggerService.warn('Overriding Customer type');
      widgetConf.context.customer_type_id = this.queryParamsData.widgetContext.customer_type_id;
    }
    if (this.queryParamsData.widgetContext.show_questions_before_booking_process) {
      LoggerService.warn('Overriding show questions before booking process');
      widgetConf.context.show_questions_before_booking_process =
        this.queryParamsData.widgetContext.show_questions_before_booking_process;
    }
    if (this.queryParamsData.widgetContext.steps?.length > 0) {
      LoggerService.warn('Overriding steps');
      widgetConf.context.steps = this.queryParamsData.widgetContext.steps;
    }
    if (this.queryParamsData.widgetContext.ask_for_customer_notification_preference) {
      LoggerService.warn('Overriding ask for customer notification preference');
      widgetConf.context.ask_for_customer_notification_preference =
        this.queryParamsData.widgetContext.ask_for_customer_notification_preference;
    }
    if (this.queryParamsData.widgetContext.show_meeting_type_picker) {
      LoggerService.warn('Overriding show meeting type picker');
      widgetConf.context.show_meeting_type_picker =
        this.queryParamsData.widgetContext.show_meeting_type_picker;
    }
    if (this.queryParamsData.widgetContext.ask_customer_city) {
      LoggerService.warn('Overriding ask customer city');
      widgetConf.context.ask_customer_city = this.queryParamsData.widgetContext.ask_customer_city;
    }
    if (this.queryParamsData.widgetContext.ask_customer_zip) {
      LoggerService.warn('Overriding ask customer zip');
      widgetConf.context.ask_customer_zip = this.queryParamsData.widgetContext.ask_customer_zip;
    }
    if (this.queryParamsData.widgetContext.utm_source) {
      LoggerService.warn('Overriding utm source');
      widgetConf.context.utm_source = this.queryParamsData.widgetContext.utm_source;
    }
    if (this.queryParamsData.widgetContext.utm_medium) {
      LoggerService.warn('Overriding utm medium');
      widgetConf.context.utm_medium = this.queryParamsData.widgetContext.utm_medium;
    }
    if (this.queryParamsData.widgetContext.utm_campaign) {
      LoggerService.warn('Overriding utm campaign');
      widgetConf.context.utm_campaign = this.queryParamsData.widgetContext.utm_campaign;
    }
    if (this.queryParamsData.widgetContext.utm_content) {
      LoggerService.warn('Overriding utm content');
      widgetConf.context.utm_content = this.queryParamsData.widgetContext.utm_content;
    }
    if (this.queryParamsData.widgetContext.utm_term) {
      LoggerService.warn('Overriding utm term');
      widgetConf.context.utm_term = this.queryParamsData.widgetContext.utm_term;
    }

    if (this.queryParamsData.widgetContext.auto_skip_steps !== undefined) {
      LoggerService.warn('Overriding auto_skip_steps');
      widgetConf.context.auto_skip_steps = ((!isNaN(this.queryParamsData.widgetContext.auto_skip_steps) && Number(this.queryParamsData.widgetContext.auto_skip_steps) === 1) ? 1 : 0);
    }

    LoggerService.log('Inside overrideWidgetConfigDataFromQueryParamData widgetConf ', widgetConf);
    return widgetConf;
  }

  setCustomFieldValuesFromIframePostMessage(customFieldValues: { id?: number, value?: any, ignore?: boolean }[]): void {
    this.queryParamsData.customFieldValues = customFieldValues;
  }

  setCustomerDataFromIframePostMessage(customerConfData: CustomerDbModel): void {
    this.widgetService.customerConfData = customerConfData;
    this.queryParamsData.customerConfData = customerConfData;
  }

  setWidgetContextDataFromIframePostMessage(widgetContext: WidgetContext): void {
    this.queryParamsData.widgetContext = widgetContext;
  }

  setReferenceAppointmentUuidFromIframePostMessage(appointmentUuid: string): void {
    this.validateReferenceAppointmentAndSetInContext(appointmentUuid);
  }

  private setWidgetContextData(result: Params): void {
    LoggerService.log('Inside setWidgetContextData result ', result);
    if (result.worker_email) {
      this.queryParamsData.widgetContext.worker_email = result.worker_email;
    }
    if (result.worker_id) {
      this.queryParamsData.widgetContext.worker_id = Number(result.worker_id);
    }
    if (result.worker_ids) {
      this.queryParamsData.widgetContext.worker_ids = result.worker_ids.split(',').map(id => Number(id));
    }
    if (result.worker_uuid) {
      this.queryParamsData.widgetContext.worker_uuid = result.worker_uuid;
    }

    if (result.auto_skip_steps !== undefined) {
      this.queryParamsData.widgetContext.auto_skip_steps = ((!isNaN(result.auto_skip_steps) && Number(result.auto_skip_steps) === 1) ? 1 : 0);
    }

    if (result.appointment_service_ids) {
      // LoggerService.log('result.appointment_service_ids ', result.appointment_service_ids);
      this.queryParamsData.widgetContext.appointment_service_ids = result.appointment_service_ids.split(',').map(id => Number(id));
    }
    if (result.store_id) {
      this.queryParamsData.widgetContext.store_id = Number(result.store_id);
    }
    if (result.locale) {
      this.queryParamsData.widgetContext.locale = result.locale;
    }
    
    const KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
    
    const keyMappings = this.getKeyMappings(KEYS);

    keyMappings.forEach(({ underscoreKey, hyphenKey }) => {
      if (result[underscoreKey] || result[hyphenKey]) {
        this.queryParamsData.widgetContext[underscoreKey] = result[underscoreKey] || result[hyphenKey];
      }
    });
    
    if (result.date) {
      this.queryParamsData.widgetContext.date = result.date;
    }
    if (result.is_internal || Number(result.is_internal) === 0) {
      this.queryParamsData.widgetContext.is_internal = result.is_internal === 1;
    }
    if (result.booking_questions) {
      this.queryParamsData.widgetContext.booking_questions = result.booking_questions.split(',').map(id => Number(id));
    }
    if (result.meeting_providers) {
      this.queryParamsData.widgetContext.meeting_providers = result.meeting_providers.split(',').map(id => Number(id));
    }
    if (result.customer) {
      this.queryParamsData.widgetContext.customer = result.customer.split(',');
    }
    if (result.customer_required) {
      this.queryParamsData.widgetContext.customer_required = result.customer_required.split(',');
    }
    /*if (result.customer_readonly) {
      this.queryParamsData.widgetContext.customer_readonly = result.customer_readonly.split(',');
    }*/
    if (result.customer_type_id) {
      this.queryParamsData.widgetContext.customer_type_id = result.customer_type_id;
    }
    if (result.show_questions_before_booking_process || Number(result.show_questions_before_booking_process) === 0) {
      this.queryParamsData.widgetContext.show_questions_before_booking_process =
        Number(result.show_questions_before_booking_process) === 1;
    }
    if (result.steps) {
      this.queryParamsData.widgetContext.steps = result.steps.split(',').map(id => Number(id));
    }
    if (result.ask_for_customer_notification_preference || Number(result.ask_for_customer_notification_preference) === 0) {
      this.queryParamsData.widgetContext.ask_for_customer_notification_preference =
        Number(result.ask_for_customer_notification_preference) === 1;
    }
    if (result.show_meeting_type_picker || Number(result.show_meeting_type_picker) === 0) {
      this.queryParamsData.widgetContext.show_meeting_type_picker =
        Number(result.show_meeting_type_picker) === 1;
    }
    if (Number(result?.button_previous_step_1) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('booking-questions-previous-btn');
    }
    if (Number(result?.button_next_step_1) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('booking-questions-next-question-btn');
      this.domManuplationService.setCustomNextPreviewButtonStyle('booking-questions-finish-last-btn');
    }
    if (Number(result?.button_previous_step_2) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('meeting-type-previous-btn');
    }
    if (Number(result?.button_next_step_2) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('meeting-type-selection-next-btn');
    }
    if (Number(result?.button_previous_step_3) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('store-selection-previous-btn');
    }
    if (Number(result?.button_next_step_3) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('store-selection-next-btn');
    }
    if (Number(result?.button_previous_step_4) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('services-selection-previous-btn');
    }
    if (Number(result?.button_next_step_4) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('services-selection-next-btn');
    }
    if (Number(result?.button_previous_step_5) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('workers-selection-previous-btn');
    }
    if (Number(result?.button_next_step_5) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('workers-selection-next-btn');
    }
    if (Number(result?.button_previous_step_6) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('slot-selection-previous-btn');
    }
    if (Number(result?.button_next_step_6) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('slot-selection-next-btn');
    }
    if (Number(result?.button_previous_step_7) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('booking-questions-previous-btn');
    }
    if (Number(result?.button_next_step_7) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('booking-questions-next-question-btn');
      this.domManuplationService.setCustomNextPreviewButtonStyle('booking-questions-finish-last-btn');
    }
    if (Number(result?.button_previous_step_8) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('personal-form-previous-btn');
    }
    if (Number(result?.button_next_step_8) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('personal-form-finish-btn');
    }
    if (Number(result?.button_previous_step_9) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('payment-method-previous-btn');
    }
    if (Number(result?.button_next_step_9) === 0) {
      this.domManuplationService.setCustomNextPreviewButtonStyle('payment-method-book-btn');
    }
    if ([1, 0].includes(Number(result?.ask_customer_city))) {
      this.queryParamsData.widgetContext.ask_customer_city = Number(result?.ask_customer_city) === 1 ? 1 : 0;
    }
    if ([1, 0].includes(Number(result?.ask_customer_zip))) {
      this.queryParamsData.widgetContext.ask_customer_zip = Number(result?.ask_customer_zip) === 1 ? 1 : 0;
    }
  }

  private setCustomerConfData(result: Params): void {
    if (result.customer_uuid) {
      this.widgetService.customerConfData.uuid = result.customer_uuid;
      this.queryParamsData.customerConfData.uuid = result.customer_uuid;
    }
    if (result.customer_email) {
      this.widgetService.customerConfData.email = result.customer_email;
      this.queryParamsData.customerConfData.email = result.customer_email;
    }
    if (result.customer_prename) {
      this.widgetService.customerConfData.prename = result.customer_prename;
      this.queryParamsData.customerConfData.prename = result.customer_prename;
    }
    if (result.customer_lastname) {
      this.widgetService.customerConfData.lastname = result.customer_lastname;
      this.queryParamsData.customerConfData.lastname = result.customer_lastname;
    }
    if (result.customer_mobile) {
      this.widgetService.customerConfData.mobile = result.customer_mobile;
      this.queryParamsData.customerConfData.mobile = result.customer_mobile;
    }
    if (result.customer_phone) {
      this.widgetService.customerConfData.phone = result.customer_phone;
      this.queryParamsData.customerConfData.phone = result.customer_phone;
    }
    if (result.customer_street) {
      this.widgetService.customerConfData.street = result.customer_street;
      this.queryParamsData.customerConfData.street = result.customer_street;
    }
    if (result.customer_zip) {
      this.widgetService.customerConfData.zip = result.customer_zip;
      this.queryParamsData.customerConfData.zip = result.customer_zip;
    }
    if (result.customer_city) {
      this.widgetService.customerConfData.city = result.customer_city;
      this.queryParamsData.customerConfData.city = result.customer_city;
    }
    if (result.customer_salutation) {
      this.widgetService.customerConfData.salutation = result.customer_salutation;
      this.queryParamsData.customerConfData.salutation = result.customer_salutation;
    }
    if (result.customer_birthday) {
      this.widgetService.customerConfData.birthday = result.customer_birthday;
      this.queryParamsData.customerConfData.birthday = result.customer_birthday;
    }
    if (result.customer_notification_preference) {
      this.widgetService.customerConfData.notification_preference = result.customer_notification_preference;
      this.queryParamsData.customerConfData.notification_preference = result.customer_notification_preference;
    }
    if (result.comment) {
      this.widgetService.customerConfData.comment = result.comment;
      this.queryParamsData.customerConfData.comment = result.comment;
    }
    if (result.meeting_type_id) {
      this.widgetService.customerConfData.meeting_type_id = Number(result.meeting_type_id);
      this.queryParamsData.customerConfData.meeting_type_id = Number(result.meeting_type_id);
    }
    if (result.country) {
      this.widgetService.customerConfData.country = Number(result.country);
      this.queryParamsData.customerConfData.country = Number(result.country);
    }
    if (result.title) {
      this.widgetService.customerConfData.title = Number(result.title);
      this.queryParamsData.customerConfData.title = Number(result.title);
    }
    if (result.company) {
      this.widgetService.customerConfData.company = result.company;
      this.queryParamsData.customerConfData.company = result.company;
    }
    if (result?.policy) {
      this.widgetService.customerConfData.policy = (result.policy).toLowerCase() === 'true' ? true : false;
      this.queryParamsData.customerConfData.policy = (result.policy).toLowerCase() === 'true' ? true : false;
    }
  }

  private setCustomFieldValues(result: Params): void {
    const paramKeys: string[] = Object.keys(result);
    const customFieldValues: { id?: number, value?: any, ignore?: boolean }[] = [];
    if (paramKeys?.length > 0) {
      const customFieldKeys: string[] = paramKeys.filter((item: string) => {
        return item.indexOf('custom_field') > -1;
      });
      for (const customFieldKey of customFieldKeys) {
        const customFieldValue: { id?: number, value?: any, ignore?: boolean } = {};
        customFieldValue.id = Number(this.helperService.findStringBetween(customFieldKey, '[', ']'));
        customFieldValue.value = result[customFieldKey];
        customFieldValue.ignore = false;
        customFieldValues.push(customFieldValue);
      }
    }
    LoggerService.log('Inside Route Service final customFieldValues ', customFieldValues);
    this.queryParamsData.customFieldValues = customFieldValues;
  }

  isAutoSkipStepsEnabled(): boolean {
    return (
      Number(this.widgetService.widgetConf.context.auto_skip_steps) === 1 ||
      this.widgetService.widgetConf.context.auto_skip_steps === undefined
    );
  }

  validateReferenceAppointmentAndSetInContext(appointmentUuid: string): void {
    this.setReferenceAppointmentUuid = appointmentUuid;
    this.customEventService.queryStringAppointmentUuid.emit(appointmentUuid);
  }

  getKeyMappings(keys: string[]): { underscoreKey: string, hyphenKey: string }[] {
    return keys.map(key => {
      return {
        underscoreKey: key,
        hyphenKey: key.replace(/_/g, '-')
      };
    });
  }
}
