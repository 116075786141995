import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GTM_EVENTS } from '../../constants/app.constants';
import { DATE_FORMAT, DATE_ISO_FORMAT } from '../../constants/date.constants';
import { IS_CALENSO_AUTH_REQUIRED } from '../../constants/http-context';
import { BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS } from '../../constants/local-storage.constants';
import { AppointmentBookingModel } from '../../models/appointment-booking.model';
import { AppointmentServiceModel } from '../../models/appointment-service.model';
import { DayWiseFreeSlotsDao } from '../../models/availability.model';
import { CouponDbModel } from '../../models/coupon-db.model';
import { CustomFieldModel, CustomFieldOptionModel } from '../../models/custom-field.model';
import { CustomerDbModel } from '../../models/customer.model';
import { MeetingTypesModel } from '../../models/meeting-types.model';
import { FeaturesDao, PartnerDbModel, TokenType } from '../../models/partner-db.model';
import { VerifyPaymentStatusModel } from '../../models/partner-setting.model';
import { AppointmentCartItem, CurrentSelectedAppointmentState, PaymentMethodData, SelectedBookingDataModel } from '../../models/selected-booking-data.model';
import { StoreDbModel } from '../../models/store-db.model';
import { UserInfo } from '../../models/user.model';
import { WidgetAppointmentGroupModel } from '../../models/widget-appointment-group.model';
import { WidgetContext } from '../../models/widget-conf.model';
import { WidgetTemplateModel } from '../../models/widget-template.model';
import { DateUtilService } from '../utils/date-util.service';
import { GoogleAnalyticsService } from '../utils/google-analytics.service';
import { HelperService } from '../utils/helper.service';
import { HttpClientService } from '../utils/http-client.service';
import { LocalstorageService } from '../utils/localstorage.service';
import { LoggerService } from '../utils/logger.service';
import { SwRouteService } from '../utils/sw-route.service';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  readonly dateFormat = DATE_FORMAT;
  readonly dateIsoFormat = DATE_ISO_FORMAT;

  protected featuresList: { [key: string]: boolean } = {};
  selectedBookingData = new SelectedBookingDataModel();
  partnerData: PartnerDbModel;
  widgetTemplates: WidgetTemplateModel[] = [];
  lastView: string;
  hiddenCustomFields: CustomFieldModel[] = [];
  gtmStartTriggered = false;
  initialSmartWidgetView: string;

  constructor(
    private httpClientService: HttpClientService,
    private localstorageService: LocalstorageService,
    private dateUtilService: DateUtilService,
    private swRouteService: SwRouteService,
    private helperService: HelperService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {
  }

  initializeBookingWidget(partnerUUID: string, tokenType: TokenType): Observable<PartnerDbModel> {
    const body: { partner_uuid: string, token_type: TokenType } = { partner_uuid: partnerUUID, token_type: tokenType };
    return this.httpClientService.post('partners/information', body, {
      context: new HttpContext().set(IS_CALENSO_AUTH_REQUIRED, true)
    }).pipe(
      switchMap((partner: PartnerDbModel) => {
        this.loadSubscriptionFeatures(partner.features);
        return of(partner);
      })
    );
  }

  protected loadSubscriptionFeatures(features: FeaturesDao[]): void {
    if (features?.length > 0) {
      features.forEach(feature => {
        this.featuresList[feature.feature.name] = true;
      });
    }
  }

  hasFeature(feature: string): boolean {
    return this.featuresList[feature] ? this.featuresList[feature] : false;
  }

  getWidgetTemplates(): Observable<WidgetTemplateModel[]> {
    LoggerService.log('Inside getWidgetTemplates api call');
    return this.httpClientService.get('templates/widget', {});
  }

  getStores(appointmentServiceId: number[] | null): Observable<StoreDbModel[]> {
    const body: {
      appointment_service_ids: number[] | null
    } = {
      appointment_service_ids: appointmentServiceId
    };
    return this.httpClientService.post('stores/stores_with_workers', body, {}).pipe(
      (switchMap((result: StoreDbModel[]) => {
        if (result?.length > 0) {
          return of(this.helperService.sortByNumber(result, 'position'));
        } else {
          return of([]);
        }
      }))
    );
  }

  getStoresByPostCode(postcode: string): Observable<StoreDbModel[]> {
    return this.httpClientService.post('stores/store_by_postcode', { postcode }, {}).pipe(
      switchMap((stores: StoreDbModel[]) => {
        return of(stores?.length ? this.helperService.sortByNumber(stores, 'position') : []);
      })
    );
  }

  getAppointmentSevicesByMeetingType(meetingTypeId: number): Observable<AppointmentServiceModel[]> {
    const body: {
      meeting_type_id: number
    } = { meeting_type_id: meetingTypeId };
    return this.httpClientService.post(`appointment_services/appointment_services_by_meeting_type`, body, {});
  }

  getAppointmentSevices(body: {
    store_id: number, worker_id: number, worker_email?: string
  }): Observable<AppointmentServiceModel[]> {
    return this.httpClientService.post(`appointment_services/appointment_services_by_store`, body, {});
  }

  public getWidgetAppointmentGroups(): Observable<WidgetAppointmentGroupModel[]> {
    return this.httpClientService.get(`widget_appointment_groups`, {});
  }

  getDaysWiseFreeSlots(
    workerId: number,
    storeId: number,
    appointmentIds: string,
    startDate: string
  ): Observable<DayWiseFreeSlotsDao> {
    const body = {
      worker_id: workerId,
      appointment_ids: appointmentIds,
      store_id: storeId,
      start: startDate,
    };
    return this.httpClientService.post('availabilities/free_appointments', body, {})
  }

  verifyOnlinePaymentStatus(): Observable<VerifyPaymentStatusModel> {
    return this.httpClientService.get('partners/verify_online_payment_status', {});
  }

  applyCoupon(coupon: string): Observable<CouponDbModel> {
    const body: { coupon: string } = { coupon };
    return this.httpClientService.post(`coupons/verify`, body, {});
  }

  bookAppointments(bookingItems: AppointmentBookingModel[]):
    Observable<{ success: boolean, errors: { message: string }[] }> {
    return this.httpClientService.post('appointments/book', bookingItems, {});
  }

  setCustomFieldValuesInLocalStorage(
    customFieldValues: {
      custom_field_id: number,
      type: string,
      value?: any,
      file_ids?: any
    }[]
  ): void {
    this.localstorageService.setItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_CUSTOM_FIELD + this.partnerData.booking_name,
      JSON.stringify(customFieldValues)
    );
  }

  setAppointmentStateInLocalStorage(
    currentSelectedAppointmentState: CurrentSelectedAppointmentState
  ): void {
    this.localstorageService.setItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_STATE + this.partnerData.booking_name,
      JSON.stringify(currentSelectedAppointmentState)
    );
  }

  setAppointmentCartItemsInLocalStorage(
    cartItems: AppointmentCartItem[]
  ): void {
    this.localstorageService.setItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_CART_ITEMS + this.partnerData.booking_name,
      JSON.stringify(cartItems)
    );
  }

  setAppointmentSelectedUserInLocalStorage(
    selectedUser: UserInfo
  ): void {
    this.localstorageService.setItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_SELECTED_USER + this.partnerData.booking_name,
      JSON.stringify(selectedUser)
    );
  }

  loadAppointmentStateFromLocalStorage(): void {
    const value: string = this.localstorageService.getItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_STATE + this.partnerData.booking_name
    );
    if (value) {
      this.selectedBookingData.currentSelectedAppointmentState = JSON.parse(value);
    }
  }

  loadAppointmentCartItemsFromLocalStorage(): void {
    const value: string = this.localstorageService.getItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_CART_ITEMS + this.partnerData.booking_name
    );
    if (value) {
      this.selectedBookingData.cartItems = JSON.parse(value);
    }
  }

  loadAppointmentSelectedUserFromLocalStorage(): void {
    const value: string = this.localstorageService.getItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_SELECTED_USER + this.partnerData.booking_name
    );
    if (value) {
      this.selectedBookingData.selectedUser = JSON.parse(value);
    }
  }

  removeCustomFieldValuesByIds(
    ids: number[],
    customFieldValues: { custom_field_id: number, type: string, value?: any, file_ids?: any }[]
  ): { custom_field_id: number, type: string, value?: any, file_ids?: any }[] {
    customFieldValues = customFieldValues.filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
      return ids.indexOf(item.custom_field_id) === -1;
    });
    return customFieldValues;
  }

  setupCustomFieldSelectedValues(
    widgetQuestion: CustomFieldModel,
    customFieldValues: { custom_field_id: number, type: string, value?: any, file_ids?: any }[]
  ): { custom_field_id: number, type: string, value?: any, file_ids?: any, supported_appointment_service_ids?: number[] }[] {
    customFieldValues = customFieldValues.filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
      return item.custom_field_id !== widgetQuestion.id;
    });
    this.updateCustomFieldValues(widgetQuestion, customFieldValues);

    if (widgetQuestion.customFieldDependencies?.length > 0) {
      for (const dependendantWidgetQuestion of widgetQuestion.customFieldDependencies) {
        customFieldValues = customFieldValues.filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
          return item.custom_field_id !== dependendantWidgetQuestion.id;
        });
        this.updateCustomFieldValues(dependendantWidgetQuestion, customFieldValues, true);
      }
    }
    return customFieldValues;
  }

  handleHiddenCustomFields(
    finalCustomFieldValues: {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any
    }[],
    hiddenCustomFields: CustomFieldModel[]
  ): {
    custom_field_id: number,
    type: string,
    value?: any, file_ids?: any
  }[] {
    if (hiddenCustomFields?.length > 0) {
      hiddenCustomFields = hiddenCustomFields
        .filter((v: CustomFieldModel, i: number, a: CustomFieldModel[]) => a.findIndex(t => (t.id === v.id)) === i);
      const queryParamCustomFieldValues: { id?: number, value?: any, ignore?: boolean }[] = this.swRouteService.getCustomFieldValues();
      if (queryParamCustomFieldValues?.length > 0) {
        for (const hiddenCustomField of hiddenCustomFields) {
          const foundedQueryParamCustomField: { id?: number, value?: any, ignore?: boolean } = queryParamCustomFieldValues.find(
            (item: { id?: number, value?: any, ignore?: boolean }) => {
              return item.id === hiddenCustomField.id;
            }
          );

          if (!foundedQueryParamCustomField?.ignore && foundedQueryParamCustomField?.id) {
            LoggerService.log('Loading custom field data from queryarams ');
            this.prefillCustomFieldValueFromQueryParams(hiddenCustomField, foundedQueryParamCustomField.value);
          }
          finalCustomFieldValues = finalCustomFieldValues
            .filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
              return item.custom_field_id !== hiddenCustomField.id;
            });
          this.updateCustomFieldValues(hiddenCustomField, finalCustomFieldValues);
        }
      } else {
        for (const hiddenCustomField of hiddenCustomFields) {
          finalCustomFieldValues = finalCustomFieldValues
            .filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
              return item.custom_field_id !== hiddenCustomField.id;
            });
          finalCustomFieldValues.push({
            custom_field_id: Number(hiddenCustomField.id),
            type: hiddenCustomField.type,
            value: undefined
          });
        }
      }
    }
    return finalCustomFieldValues;
  }

  prefillCustomFieldValueFromQueryParams(customField: CustomFieldModel, value: any): void {
    LoggerService.log('Inside booking service prefillCustomFieldValueFromQueryParams value is ', value, 'customField', customField);
    if (customField.type === 'select') {
      if (customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          customField.customerSelectedValue = value.map(id => Number(id));
        } else {
          customField.customerSelectedValue = [Number(value)];
        }
      } else {
        customField.customerSelectedValue = (value) ? Number(value) : undefined;
      }
    } else if (customField.type === 'image-select' || customField.type === 'card-select') {
      if (customField.is_multiple_select === 1) {
        if (Array.isArray(value)) {
          customField.customerSelectedValue = value.map(id => Number(id));
        } else {
          customField.customerSelectedValue = [Number(value)];
        }
      } else {
        customField.customerSelectedValue = (value) ? [Number(value)] : [];
      }
    } else if (customField.type === 'file') {
      // TODO currently file custom field is not supporting query params
    } else if (customField.type === 'radio') {
      customField.customerSelectedValue = Number(value);
      if (customField.custom_field_options?.length > 0) {
        const options = (Array.isArray(customField.custom_field_options[0]) ? customField.custom_field_options[0] : customField.custom_field_options);
        const foundedCFOption: CustomFieldOptionModel = options.find((item: CustomFieldOptionModel) => {
          return item.id === customField.customerSelectedValue;
        });
        if (foundedCFOption) {
          // this.onRadioValueChanged(foundedCFOption, false);
        }
      }
    } else if (customField.type === 'date') {
      customField.customerSelectedValue = value;
      // this.setSelectedDate(value);
    } else if (customField.type === 'checkbox') {
      if (value === true || value === 'true') {
        customField.customerSelectedValue = true;
      } else {
        customField.customerSelectedValue = false;
      }
    } else {
      customField.customerSelectedValue = value;
    }

    LoggerService.log('Customer selected value this.customField.customerSelectedValue ', customField.customerSelectedValue);
  }

  setupFinalCustomFields(
    widgetQuestions: CustomFieldModel[],
    customFieldValues: {
      custom_field_id: number,
      type: string,
      value?: any, file_ids?: any
    }[] = []
  ): {
    custom_field_id: number, type: string, value?: any, file_ids?: any, option_values?: {
      custom_field_option_id: number,
      custom_field_option_value: string
    }[]
  }[] {
    // const customFieldValues: { custom_field_id: number, type: string, value?: any, file_ids?: any }[] = [];
    for (const widgetQuestion of widgetQuestions) {
      customFieldValues = customFieldValues.filter((item: { custom_field_id: number, type: string, value?: any, file_ids?: any }) => {
        return item.custom_field_id !== widgetQuestion.id;
      });
      this.updateCustomFieldValues(widgetQuestion, customFieldValues);
      LoggerService.log('widgetQuestion ', widgetQuestion);
      LoggerService.log('widgetQuestion.customFieldDependencies ', widgetQuestion.customFieldDependencies);
      if (widgetQuestion.customFieldDependencies?.length > 0) {
        for (const dependendantWidgetQuestion of widgetQuestion.customFieldDependencies) {
          this.updateCustomFieldValues(dependendantWidgetQuestion, customFieldValues);
        }
      }
    }
    LoggerService.log('Final customFieldValues ', customFieldValues);
    return customFieldValues;
  }

  updateCustomFieldValues(
    widgetQuestion: CustomFieldModel,
    customFieldValues: {
      custom_field_id: number,
      type: string,
      value?: any,
      file_ids?: any,
      option_values?: {
        custom_field_option_id: number,
        custom_field_option_value: string
      }[],
      supported_appointment_service_ids?: number[],
    }[],
    checkForDepth = false
  ): void {
    LoggerService.log('updating custom field value for custom field id:', widgetQuestion.id);
    if (!widgetQuestion.customerSelectedValue) {
      LoggerService.warn('Value is not selected for custom field ', widgetQuestion);
      return;
    }
    if (widgetQuestion.type === 'select') {
      if (Array.isArray(widgetQuestion.customerSelectedValue)) {
        const finalValues = [];
        for (const value of widgetQuestion.customerSelectedValue) {
          if (Array.isArray(value)) {
            finalValues.push(...value);
          } else {
            finalValues.push(value);
          }
        }

        customFieldValues.push({
          custom_field_id: Number(widgetQuestion.id),
          type: 'select',
          value: finalValues,
          supported_appointment_service_ids: this.getSupportedAppointmentServiceIds(widgetQuestion)
        });
      } else {
        if (widgetQuestion.customerSelectedValue) {
          customFieldValues.push({
            custom_field_id: Number(widgetQuestion.id),
            type: 'select',
            value: [widgetQuestion.customerSelectedValue],
            supported_appointment_service_ids: this.getSupportedAppointmentServiceIds(widgetQuestion)
          });
        }
      }
    } else if (widgetQuestion.type === 'file') {
      const finalValues = [];
      for (const value of widgetQuestion.customerSelectedValue) {
        if (Array.isArray(value)) {
          finalValues.push(...value);
        } else {
          finalValues.push(value);
        }
      }

      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: 'file',
        file_ids: Object.assign([], finalValues)
      });
    } else if (widgetQuestion.type === 'radio') {
      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: 'radio',
        value: widgetQuestion.customerSelectedValue.toString(),
        supported_appointment_service_ids: this.getSupportedAppointmentServiceIds(widgetQuestion)
      });
    } else if (widgetQuestion.type === 'card-select') {
      const finalValues = [];
      for (const value of widgetQuestion.customerSelectedValue) {
        if (Array.isArray(value)) {
          finalValues.push(...value);
        } else {
          finalValues.push(value);
        }
      }

      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: 'card-select',
        value: finalValues,
        supported_appointment_service_ids: this.getSupportedAppointmentServiceIds(widgetQuestion)
      });
    } else if (widgetQuestion.type === 'image-select') {
      const finalValues = [];
      for (const value of widgetQuestion.customerSelectedValue) {
        if (Array.isArray(value)) {
          finalValues.push(...value);
        } else {
          finalValues.push(value);
        }
      }

      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: 'image-select',
        value: finalValues,
        supported_appointment_service_ids: this.getSupportedAppointmentServiceIds(widgetQuestion)
      });
    } else if (widgetQuestion.type === 'number-select') {
      LoggerService.log(
        'Inside updateCustomFieldValues number-select widgetQuestion.customerSelectedValue ',
        widgetQuestion.customerSelectedValue
      );
      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        value: widgetQuestion.customerSelectedValue[0].map(item => item.custom_field_option_id),
        option_values: widgetQuestion.customerSelectedValue,
        type: widgetQuestion.type,
        supported_appointment_service_ids: this.getSupportedAppointmentServiceIds(widgetQuestion)
      });
    } else if (widgetQuestion.type === 'worker-select') {
      if (widgetQuestion.customerSelectedValue) {
        let finalValues = [];
        if (Array.isArray(widgetQuestion.customerSelectedValue)) {
          for (const value of widgetQuestion.customerSelectedValue) {
            finalValues.push(value);
          }
        } else {
          finalValues = [widgetQuestion.customerSelectedValue];
        }

        customFieldValues.push({
          custom_field_id: Number(widgetQuestion.id),
          type: 'worker-select',
          value: finalValues,
          supported_appointment_service_ids: this.getSupportedAppointmentServiceIds(widgetQuestion)
        });
      }
    } else {
      let finalValues: any;
      if (Array.isArray(widgetQuestion.customerSelectedValue)) {
        finalValues = widgetQuestion.customerSelectedValue[0];
      } else {
        finalValues = widgetQuestion.customerSelectedValue.toString();
      }

      customFieldValues.push({
        custom_field_id: Number(widgetQuestion.id),
        type: '',
        value: finalValues
      });
    }

    // Added code to support N level of dependent questions
    if (checkForDepth) {
      if (widgetQuestion?.customFieldDependencies?.length > 0) {
        for (const dependendantWidgetQuestion of widgetQuestion.customFieldDependencies) {
          this.updateCustomFieldValues(dependendantWidgetQuestion, customFieldValues, true);
        }
      }
    }
  }

  getSupportedAppointmentServiceIds(widgetQuestion: CustomFieldModel): number[] {
    const supported_appointment_service_ids = [];
    if (widgetQuestion?.custom_field_options) {
      const options = (widgetQuestion.custom_field_options &&
        Array.isArray(widgetQuestion.custom_field_options) &&
        widgetQuestion.custom_field_options[0] != undefined &&
        Array.isArray(widgetQuestion.custom_field_options[0]))
        ? widgetQuestion.custom_field_options[0]
        : widgetQuestion.custom_field_options;
      options
        .filter((field) => {
          if (Array.isArray(widgetQuestion.customerSelectedValue)) {
            if (Array.isArray(widgetQuestion.customerSelectedValue[0])) {
              return widgetQuestion.customerSelectedValue[0].includes(field.id);
            } else {
              return widgetQuestion.customerSelectedValue.includes(field.id);
            }
          } else {
            return Number(widgetQuestion.customerSelectedValue) === field.id;
          }
        })
        .map(field => {
          if (field.supported_appointment_service_ids) {
            const ids = JSON.parse(field.supported_appointment_service_ids);
            supported_appointment_service_ids.push(...ids);
          }
        });
    }
    return supported_appointment_service_ids;
  }

  getMeetingTypesByIds(ids: number[]): Observable<MeetingTypesModel[]> {
    const body: {
      ids: number[],
    } = { ids };
    return this.httpClientService.post('meeting_types/get_by_ids', body, {}).pipe(
      (switchMap((items: MeetingTypesModel[]) => {
        if (items?.length > 0) {
          for (const item of items) {
            item.is_multi_language = 1;
          }
          return of(items);
        } else {
          return of([]);
        }
      }))
    );
  }

  resetCurrentSelectedAppointmentStateonStoreSelection(): CurrentSelectedAppointmentState {
    const currentSelectedAppointmentState = new CurrentSelectedAppointmentState();

    if (this.selectedBookingData?.currentSelectedAppointmentState?.selectedMeetingTypeId) {
      currentSelectedAppointmentState.selectedMeetingTypeId = this.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId;
    }
    if (this.selectedBookingData?.currentSelectedAppointmentState?.selectedServiceIds?.length > 0) {
      currentSelectedAppointmentState.selectedServiceIds = this.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds;
    }

    if (
      this.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId ||
      this.selectedBookingData?.currentSelectedAppointmentState?.selectedWorkerId === 0
    ) {
      currentSelectedAppointmentState.selectedWorkerId = this.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId;
    }

    if (this.selectedBookingData?.currentSelectedAppointmentState?.selectedWorker) {
      currentSelectedAppointmentState.selectedWorker = this.selectedBookingData.currentSelectedAppointmentState.selectedWorker;
    }

    if (this.selectedBookingData?.currentSelectedAppointmentState?.selectedDate) {
      currentSelectedAppointmentState.selectedDate = this.selectedBookingData.currentSelectedAppointmentState.selectedDate;
    }

    if (this.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreId) {
      currentSelectedAppointmentState.selectedStoreId = this.selectedBookingData.currentSelectedAppointmentState.selectedStoreId;
      currentSelectedAppointmentState.selectedStore = this.selectedBookingData.currentSelectedAppointmentState.selectedStore;
    }

    if (this.selectedBookingData?.currentSelectedAppointmentState?.selectedStoreUuids) {
      currentSelectedAppointmentState.selectedStoreUuids = this.selectedBookingData.currentSelectedAppointmentState.selectedStoreUuids;
    }

    if (this.selectedBookingData?.currentSelectedAppointmentState?.storeAndWorkerPreSelectedThroughWorkerSelectField) {
      currentSelectedAppointmentState.storeAndWorkerPreSelectedThroughWorkerSelectField = this.selectedBookingData.currentSelectedAppointmentState.storeAndWorkerPreSelectedThroughWorkerSelectField;
    }

    if (this.selectedBookingData?.currentSelectedAppointmentState?.storeDefaultWorkerIds) {
      currentSelectedAppointmentState.storeDefaultWorkerIds = this.selectedBookingData.currentSelectedAppointmentState.storeDefaultWorkerIds;
    }

    return currentSelectedAppointmentState;
  }

  preInitializeWidgetContextValues(
    context: WidgetContext,
    customerConfData: CustomerDbModel
  ): void {
    if (context?.show_meeting_type_picker && customerConfData?.meeting_type_id) {
      this.selectedBookingData.currentSelectedAppointmentState.selectedMeetingTypeId = customerConfData?.meeting_type_id;
    }

    if (context?.store_id) {
      this.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = context.store_id;
    }

    if (context?.store_uuids?.length) {
      this.selectedBookingData.currentSelectedAppointmentState.selectedStoreUuids = context.store_uuids;
    }

    if (context?.appointment_service_ids) {
      this.selectedBookingData.currentSelectedAppointmentState.selectedServiceIds = context.appointment_service_ids;
    }

    if (context?.worker_id || context?.worker_id === 0) {
      this.selectedBookingData.currentSelectedAppointmentState.selectedWorkerId = context.worker_id;
    }

    if (context?.date) {
      this.selectedBookingData.currentSelectedAppointmentState.selectedDate = this.dateUtilService.getDateFromString(this.dateUtilService.getDateByPlaceholder(context.date), this.dateIsoFormat);
    }

    if (context?.workers_view) {
      this.selectedBookingData.currentSelectedAppointmentState.workers_view = context.workers_view;
    }
  }

  preInitializeWidgetUserData(customerConfData: CustomerDbModel): void {
    if (!this.selectedBookingData.selectedUser) {
      this.selectedBookingData.selectedUser = new UserInfo();
    }
    this.selectedBookingData.selectedUser.eMail = customerConfData.email ? customerConfData.email : undefined;
    this.selectedBookingData.selectedUser.forename = customerConfData.prename ? customerConfData.prename : undefined;
    this.selectedBookingData.selectedUser.lastName = customerConfData.lastname ? customerConfData.lastname : undefined;
    this.selectedBookingData.selectedUser.mobile = customerConfData.mobile ? customerConfData.mobile : undefined;
    this.selectedBookingData.selectedUser.phone = customerConfData.phone ? customerConfData.phone : undefined;
    this.selectedBookingData.selectedUser.street = customerConfData.street ? customerConfData.street : undefined;
    this.selectedBookingData.selectedUser.zip = customerConfData.zip ? customerConfData.zip : undefined;
    this.selectedBookingData.selectedUser.city = customerConfData.city ? customerConfData.city : undefined;
    this.selectedBookingData.selectedUser.comment = customerConfData.comment ? customerConfData.comment : undefined;
    this.selectedBookingData.selectedUser.gender = customerConfData.salutation ? customerConfData.salutation : undefined;
    this.selectedBookingData.selectedUser.birthday = customerConfData.birthday ? this.dateUtilService.getDateFromString(customerConfData.birthday, this.dateFormat) : undefined;
    this.selectedBookingData.selectedUser.customer_notification_preference = customerConfData.notification_preference ? customerConfData.notification_preference : undefined;
    this.selectedBookingData.selectedUser.meeting_type_id = customerConfData.meeting_type_id ? customerConfData.meeting_type_id : undefined;
    this.selectedBookingData.selectedUser.company = customerConfData?.company ? customerConfData.company : undefined;
    this.selectedBookingData.selectedUser.title = customerConfData?.title ? customerConfData.title : undefined;
    this.selectedBookingData.selectedUser.country = customerConfData?.country ? customerConfData.country : undefined;
    this.selectedBookingData.selectedUser.policy = customerConfData?.policy ? customerConfData.policy : undefined;
  }

  resetBookingDataAfterBooking(): void {
    const oldStateValues = this.selectedBookingData.currentSelectedAppointmentState;

    this.gtmStartTriggered = false;
    this.localstorageService
      .removeItem(BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_STATE + this.partnerData.booking_name);
    this.localstorageService
      .removeItem(BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_APPOINTMENT_CART_ITEMS + this.partnerData.booking_name);
    this.selectedBookingData.selectedServices = [];
    this.selectedBookingData.cartItems = [];
    this.selectedBookingData.totalBookingAmount = undefined;
    this.selectedBookingData.subTotal = undefined;
    this.selectedBookingData.totalDiscount = undefined;
    this.selectedBookingData.paymentMethodData = new PaymentMethodData();
    this.selectedBookingData.selectedCoupon = undefined;

    this.selectedBookingData.currentSelectedAppointmentState = new CurrentSelectedAppointmentState();

    // When store uuids are present preserving store and store uuids in order to resume flow on tap of rebook appointment(This has been updated for AMAG)
    if (this.selectedBookingData.currentSelectedAppointmentState?.selectedStoreUuids) {
      this.selectedBookingData.currentSelectedAppointmentState.selectedStoreId = oldStateValues.selectedStoreId;
      this.selectedBookingData.currentSelectedAppointmentState.selectedStore = oldStateValues.selectedStore;
      this.selectedBookingData.currentSelectedAppointmentState.selectedStoreUuids = oldStateValues.selectedStoreUuids;
    }
  }

  getSupportedAppointmentServiceIdsFromLocalStorage(bookingName: string): number[] {
    let customFields: any = this.localstorageService.getItem(
      BOOKING_WIDGET_LOCAL_STORAGE_CONSTANTS.BOOKING_CUSTOM_FIELD + bookingName,
    );
    if (customFields) {
      customFields = JSON.parse(customFields);
      const ids = [];
      customFields.forEach(field => {
        if (field?.supported_appointment_service_ids) {
          ids.push(...field.supported_appointment_service_ids);
        }
      });
      return ids;
    }
    return [];
  }

  triggerApoointmentStartEvent(): void {
    if (this.gtmStartTriggered === false) {
      this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_start);
      this.gtmStartTriggered = true;
    }
  }
}
