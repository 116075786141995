import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BOOKING_PAGE_VIEWS, GTM_EVENTS } from '@constants/app.constants';
import { BookingService } from '@services/feature/booking.service';
import { WidgetService } from '@services/feature/widget.service';
import { GoogleAnalyticsService } from '@services/utils/google-analytics.service';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@ui-lib/buttons/button/button.component';
import { CswTextComponent } from '@ui-lib/typography/csw-text/csw-text.component';

@Component({
  selector: 'app-booking-failed-view',
  templateUrl: './booking-failed-view.component.html',
  styleUrls: ['./booking-failed-view.component.scss'],
  standalone: true,
  imports: [CswTextComponent, ButtonComponent, TranslateModule]
})
export class BookingFailedViewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() nextPageEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() previousPageEvent: EventEmitter<any> = new EventEmitter<any>();

  protected errorMessage: string;
  private timeoutId: ReturnType<typeof setTimeout> | null = null;

  @ViewChild('failedView') failedView: ElementRef;

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    public widgetService: WidgetService,
    private bookingService: BookingService,
  ) {
  }

  ngOnInit(): void {
    this.googleAnalyticsService.emitAppointmentBookingEvent(GTM_EVENTS.appointment_booking_step_failed);

    if (this.widgetService.widgetConf?.partner?.courtesyForm === 'Du') {
      this.errorMessage = 'booking_components.errorMessageDu'
    } else {
      this.errorMessage = 'booking_components.errorMessageSie'
    }
  }

  ngAfterViewInit(): void {
    this.timeoutId = setTimeout(() => {
      if (this.failedView) {
        const windowObj = (window?.parent || window);
        windowObj.scrollTo({
          top: this.failedView.nativeElement.offsetTop,
          behavior: 'smooth'
        });
      }
    }, 200);
  }

  onAddtionalBooking(): void {
    this.bookingService.resetBookingDataAfterBooking();
    if (this.widgetService?.widgetConf?.context?.show_questions_before_booking_process) {
      this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.BOOKING_QUESTIONS_VIEW);
    } else {
      if (this.widgetService?.widgetConf?.context?.show_meeting_type_picker) {
        this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.MEETING_TYPE_VIEW);
      } else {
        this.nextPageEvent.emit(BOOKING_PAGE_VIEWS.STORE_VIEW);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
    }
  }
}
