@if (surveyService?.bookingData?.length) {
  <ng-container *ngTemplateOutlet="bookingsAvailableTemplate; context: { bookings: surveyService.bookingData }" />
}

@if (!surveyService?.bookingData || surveyService?.bookingData?.length === 0) {
  <ng-container *ngTemplateOutlet="bookingsNotAvailableTemplate" />
}

<ng-template #bookingsNotAvailableTemplate>
  <div class="row">
    <div class="col-md-12">
      <app-csw-text
        [text]="widgetService?.widgetConf | translation: 'end_text':lang | calioSafe: 'html'"
        [styleConfig]="{}"
      />

      <div class="mt-5">
        <button
          type="button"
          class="btn btn-primary"
          [ngStyle]="{
            background: widgetService?.widgetConf?.button_background_color,
            color: widgetService?.widgetConf?.button_font_color,
            borderColor: widgetService?.widgetConf?.button_background_color
          }"
          (click)="exportSurveySummary(bookingData)"
        >
          {{ "survey_component.download_summary" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #bookingsAvailableTemplate let-bookings="bookings">
  @for (bookingData of bookings; track bookingData) {
    <div class="row">
      <div class="col-md-8">
        <app-csw-text
          [text]="widgetService?.widgetConf | translation: 'end_text':lang | calioSafe: 'html'"
          [styleConfig]="{}"
        />
      </div>
      @if (bookingData) {
        <div class="col-md-4 appointment-detail-section">
          <div class="section-heading">
            {{ "survey_component.appointment_details" | translate }}
          </div>
          <div class="d-flex mb-2">
            <div>
              <mat-icon class="final-step-icon" svgIcon="calendar-regular" />
            </div>
            <div class="final-step-value ps-3">
              {{ bookingData?.start | date: "dd. MMMM yyyy":"UTC" }}
            </div>
          </div>
          <div class="d-flex mb-2">
            <div>
              <mat-icon class="final-step-icon" svgIcon="clock-regular" />
            </div>
            <div class="final-step-value ps-3">
              {{ bookingData?.start | date: "HH:mm":"UTC" }} - {{ bookingData?.end | date: "HH:mm":"UTC" }}
            </div>
          </div>
          <div class="d-flex mb-2">
            <div>
              <mat-icon class="final-step-icon" svgIcon="map-marker-alt-solid" />
            </div>
            <div class="final-step-value ps-3">
              @if (bookingData?.meeting_type_id === meetingTypeId.STORE) {
                <span class="value">
                  {{ bookingData?.location }}
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.ZOOM) {
                <span class="value">
                  <a [href]="bookingData?.location" target="_blank">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/zoom.svg"
                          class="meeting_type_id_2"
                          alt="Zoom"
                          width="16"
                        />
                      </div>
                      <div>
                        Online Zoom Meeting
                        <i class="b-fa b-fa-external-link-alt"></i>
                      </div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.CALENSO) {
                <span class="value">
                  <a [href]="bookingData?.location" target="_blank">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/calenso.svg"
                          class="meeting_type_id_3"
                          alt="Calenso"
                          width="16"
                        />
                      </div>
                      <div>
                        Online-Meeting <i class="b-fa b-fa-external-link-alt"></i>
                      </div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.PHONE) {
                <span class="value">
                  <a href="tel:{{ bookingData?.location }}">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/phone.svg"
                          class="meeting_type_id_4"
                          alt="Phone"
                          width="16"
                        />
                      </div>
                      <div>{{ bookingData?.location }}</div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.CUSTOMER) {
                <span class="value">
                  <div class="d-flex align-items-center">
                    <div class="pe-1">
                      <img
                        src="/assets/images/meetings/customer.svg"
                        class="meeting_type_id_5"
                        alt="Customer"
                        width="16"
                      />
                    </div>
                    <div>{{ bookingData?.location }}</div>
                  </div>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.UNBLU) {
                <span class="value">
                  <div class="d-flex align-items-center">
                    <div class="pe-1">
                      <img
                        src="/assets/images/meetings/unblu.svg"
                        class="meeting_type_id_6"
                        alt="Unblu"
                        width="16"
                      />
                    </div>
                    <div>
                      {{ "appointment_detail_component.join_unblu" | translate }}
                    </div>
                  </div>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.MICROSOFT_TEAMS) {
                <span class="value">
                  <a [href]="bookingData?.location" target="_blank">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/teams.svg"
                          class="meeting_type_id_7"
                          alt="Microsoft Teams"
                          width="16"
                        />
                      </div>
                      <div>
                        Microsoft Teams <i class="b-fa b-fa-external-link-alt"></i>
                      </div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.GOOGLE_MEET) {
                <span class="value">
                  <a [href]="bookingData?.location" target="_blank">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/meet.svg"
                          class="meeting_type_id_8"
                          alt="Google Meet"
                          width="16"
                        />
                      </div>
                      <div>
                        Google Meet <i class="b-fa b-fa-external-link-alt"></i>
                      </div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.GOTO_MEETING) {
                <span class="value">
                  <a [href]="bookingData?.location" target="_blank">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/gotomeeting.png"
                          class="meeting_type_id_9"
                          alt="GoTo Meeting"
                          width="16"
                        />
                      </div>
                      <div>
                        GoTo Meeting <i class="b-fa b-fa-external-link-alt"></i>
                      </div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.WEBEX) {
                <span class="value">
                  <a [href]="bookingData?.location" target="_blank">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/webex.png"
                          class="meeting_type_id_10"
                          alt="Cisco Webex"
                          width="16"
                        />
                      </div>
                      <div>
                        Cisco Webex <i class="b-fa b-fa-external-link-alt"></i>
                      </div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.WILL_CALL_YOU) {
                <span class="value">
                  <a href="tel:{{ bookingData?.location }}">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/phone.svg"
                          class="meeting_type_id_11"
                          alt="Phone"
                          width="16"
                        />
                      </div>
                      <div>{{ bookingData?.location }}</div>
                    </div>
                  </a>
                </span>
              }
              @if (bookingData?.meeting_type_id === meetingTypeId.METAVERSE) {
                <span class="value">
                  <a href="tel:{{ bookingData?.location }}">
                    <div class="d-flex align-items-center">
                      <div class="pe-1">
                        <img
                          src="/assets/images/meetings/metaverse.svg"
                          class="meeting_type_id_11"
                          alt="Metaverse"
                          width="16"
                        />
                      </div>
                      @if ((bookingService.widgetTemplates | calioMeetingTemplate: { id: 13, type: 'meeting_template' }); as widgetTemplate) {
                        <div class="ps-2">
                          <div [innerHTML]="(widgetTemplate.titleTemplate | translation: 'template_content': lang) | calioSafe: 'html'"></div>
                        </div>
                      }
                    </div>
                  </a>
                </span>
              }
            </div>
          </div>
          <div class="mt-3">
            <button
              type="button"
              class="btn btn-primary"
              [ngStyle]="{
                background: widgetService?.widgetConf?.button_background_color,
                color: widgetService?.widgetConf?.button_font_color,
                borderColor: widgetService?.widgetConf?.button_background_color
              }"
              (click)="exportSurveySummary(bookingData)"
            >
              {{ "survey_component.download_summary" | translate }}
            </button>
          </div>
        </div>
      }
    </div>
  }
</ng-template>
