@if (showBookingQuestions) {
<div>
  @if ((widgetService.widgetConf | translation: 'lead_intro_text': lang)?.trim() !== '') {
    <app-info-card [showBody]="true">
      <div cardBody>
        <app-csw-text
          [text]="widgetService.widgetConf | translation : 'lead_intro_text' : lang"
        />
      </div>
    </app-info-card>
  }
  <div class="mt-4">
    @for (widgetQuestion of widgetQuestions; track widgetQuestion; let index = $index; let isFirst = $first; let isLast = $last) {
      <form #bookingQuestionForm="ngForm">
        @if (activeQuestion === index) {
          <app-booking-question
            [customField]="widgetQuestion"
            (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
            [isNextButtonPressed]="isNextButtonPressed"
          />
          @if (widgetQuestion?.customFieldDependencies?.length > 0) { 
            @for (dependencyCustomField of widgetQuestion?.customFieldDependencies; track dependencyCustomField; let childIndex = $index; let childIsFirst = $first; let childIsLast = $last) {
              <app-booking-question
                [customField]="dependencyCustomField"
                (radioButtonChangeEvent)="onRadioButtonChangeEvent($event)"
                [isNextButtonPressed]="isNextButtonPressed"
              />
            } 
          } 
          @if (showDependenciesGhostElement) {
          <div class="skeleton-box-card mt-4">
            <div>
              <div class="row">
                @for (dependenciesGhostElement of dependenciesGhostElements; track dependenciesGhostElement) {
                  <div class="col-md-12 mb-4">
                    <div class="d-flex stats-card shadow">
                      <div class="skeleton-animation w-100 d-flex justify-content-center flex-column ps-3">
                        &lt;!&ndash;
                        <div class="bar bar-1"></div>
                        <div class="bar bar-2"></div>
                        &ndash;&gt;
                        <div>
                          <div class="stat-box-1"></div>
                          <div class="stat-box mt-1"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        } 
        @if (!showDependenciesGhostElement) {
          <div class="d-flex justify-content-end mt-4">
            @if (widgetQuestion?.is_end_of_process !== 1 && !this.isEndOfProcessByChildDependencies) {
              <div class="me-2">
                <app-button
                  id="{{ 'booking-questions-previous-btn' }}"
                  (clickEvent)="previousQuestion()"
                  [isDisabled]="isFirst"
                  [fontColor]="widgetService?.widgetConf?.button_font_color"
                  [backgroundColor]="widgetService?.widgetConf?.button_background_color"
                >
                  {{ "form_fields.previous" | translate }}
                </app-button>
              </div>
            <div>
              @if (!isLast) {
                <app-button
                  id="{{ 'booking-questions-next-btn' }}"
                  (clickEvent)="nextQuestion()"
                  [isDisabled]="bookingQuestionForm.invalid"
                  [fontColor]="widgetService?.widgetConf?.button_font_color"
                  [backgroundColor]="widgetService?.widgetConf?.button_background_color"
                >
                  {{ "form_fields.next" | translate }}
                </app-button>
              } 
              @if (isLast) {
                <app-button
                  id="{{ 'booking-questions-next-btn' }}"
                  (clickEvent)="finishLastBookingQuestion()"
                  [isDisabled]="bookingQuestionForm.invalid"
                  [fontColor]="widgetService?.widgetConf?.button_font_color"
                  [backgroundColor]="widgetService?.widgetConf?.button_background_color"
                >
                  {{ "form_fields.next" | translate }}
                </app-button>
              }
            </div>
            }
          </div>
        } }
      </form>
    }
  </div>
</div>
} 
@if (showWrokerForm && workers?.length > 0) {
  <div>
    <app-lead-workers-selection
      [workers]="workers"
      (previousPageEvent)="gotoLastQuestion()"
      (nextPageEvent)="gotoPersonalForm()"
    />
  </div>
} 
@if (showPersonalForm) {
  <div>
    @if ((widgetService.widgetConf | translation: 'lead_outro_text': lang)?.trim() !== '') {
      <div>
        <app-info-card [showBody]="true">
          <div cardBody>
            <app-csw-text
              [text]="widgetService.widgetConf | translation : 'lead_outro_text' : lang"
            />
          </div>
        </app-info-card>
      </div>
    }
    <div class="mt-4">
      <app-personal-form
        [customerRequiredFields]="customerRequiredFields"
        [userInfo]="userInfo"
        [customerFormFields]="customerFormFields"
        [meetingTypes]="meetingTypes"
        (submitEvent)="onSubmitEvent($event)"
        (previousEvent)="gobackToStepFromPersonalForm()"
      />
    </div>
  </div>
}
